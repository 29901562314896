import { LitElement, css, html } from "lit";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { get } from "../../../../../../legl-ui/utils/fetch";
import { formatDate } from "../../../../../../legl-ui/utils/functions";
import location from "../../../../../../legl-ui/utils/location.js";
import { userConfig } from "../../config/user-config.js";
import { changeReviewer } from "../../pages/engage/change-reviewer";
import "./manual_CDD_accordion.js";

export class LeglSummaryScreen extends LitElement {
  static get styles() {
    return css`
            :host {
                --legl-font-size: 11px;
                --legl-width: 75px;
                --label-font-size: 14px;
            }

            a {
                color: ${neutral["800"]};
            }

            .button-container {
                display: flex;
                justify-content: flex-end;
                margin-top: 50px;
                padding: 15px 0px;
                border-top: 1px solid var(--legl-grey-light);
            }

            .button--close {
                display: block;
                width: 100%;
            }

            .confirmation-heading {
                color: var(--lds-colour-neutral-800);
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                text-align: center;
            }

            .confirmation-text {
                color: var(--lds-colour-neutral-800);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                margin-top: 45px;
            }

            .confirmation-button-container {
                display: flex;
                justify-content: center;
                margin: 45px 0;
            }

            .details-card {
                display: grid;
                grid-template:
                    "contact created-date workflow"
                    "requested-by reviewer optional-note";
                row-gap: 35px;
                color: var(--legl-grey-dark);
                font-size: 16px;
                padding: 50px 30px;
                margin: 60px 0;
            }
            .details-card strong {
                font-weight: 600;
            }
            .details-card__contact {
                grid-area: contact;
            }
            .details-card__workflow {
                grid-area: workflow;
            }
            .details-card__created-date {
                grid-area: created-date;
            }
            .details-card__optional-note {
                grid-area: optional-note;
            }
            .details-card__reviewer {
                grid-area: reviewer;
            }
            .details-card__requested-by {
                grid-area: requested-by;
            }

            .content-card {
                background: #ffffff;
                box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.3);
                border-radius: 0.25rem;
                text-align: left;
                width: 100%;
                margin: 1rem auto;
                position: relative;
                max-width: 700px;
                padding: 0;
            }

            section {
                padding: 0;
            }

            section.complete {
                color: ${neutral["700"]};
                text-align: left;
                padding: 0 2rem;
            }

            .title-container {
                display: flex;
                justify-content: space-between;
                font-size: 1.4rem;
            }

            .heading-text {
                color: ${neutral["700"]};
            }

            .dropdown-icon {
                border-radius: 1.25rem;
                background-color: transparent;
                transition: background-color 0.4s, color 0.3s;
                padding: 0.1rem;
                align-self: center;
                color: rgba(98, 98, 98, 0.65);
                font-size: 1.6rem;
            }

            &[clickable] {
                cursor: pointer;
            }

            .reviewer-name {
                margin-bottom: 3px;
            }

            .change-reviewer {
                font-size: 10px;
                font-weight: 400;
            }
        `;
  }

  static get properties() {
    return {
      reviewer: { type: Number },
      note: { type: String },
      selectedContact: { type: Object },
      application: { type: Object },
      heading: { type: String },
      text: { type: String },
    };
  }

  get reviewerName() {
    return userConfig.engageReviewers.find(
      (el) => el.value === Number.parseInt(this.reviewer, 10),
    ).label;
  }

  get reviewDrawerUrl() {
    if (this.application.business?.id) {
      return `#/drawer/engage-review/?appId=${this.application.id}&businessId=${this.application.business?.id}`;
    }
    if (waffle.flag_is_active("on_page_screening_review")) {
      return `/engage-review/?appId=${this.application.id}`;
    }
    return `#/drawer/engage-review/?appId=${this.application.id}`;
  }

  async onUpdateClicked(e) {
    e.preventDefault();
    const updated = await changeReviewer(
      this.application?.reviewer?.pk,
      this.application.reference,
    );
    if (updated === true) {
      await this.fetchReviewInformation();
    }
  }

  async fetchReviewInformation() {
    const res = await get(`/api/engage_applications/${this.application.id}/`);
    const response = await res.json();
    this.application = { ...response };
    this.reviewer = this.application.assigned_reviewer.pk;
    this.note =
      this.application.application_events[
        this.application.application_events.length - 1
      ].comment;
    await this.requestUpdate();
  }

  onClose() {
    this.dispatchEvent(
      new CustomEvent("close", { bubbles: true, composed: true }),
    );
  }

  render() {
    return html`<h2 id="confirmationHeading" class="confirmation-heading">
                ${this.heading}
            </h2>
            <p id="confirmationText" class="confirmation-text">${this.text}</p>
            <div class="confirmation-button-container">
                <legl-button
                    id="summary-screen-view-results-button"
                    size="medium"
                    variant="outlined"
                    data-cy-summary-screen-results
                    @click=${() =>
                      location.setCurrentLocation(this.reviewDrawerUrl)}
                    >View results</legl-button
                >
            </div>
            <legl-card
                class="details-card card-bg card-border"
                title="check details"
            >
                <h1 slot="title" variant="corner">check details</h1>
                <div class="details-card__contact">
                    <strong>Contact</strong>
                    <legl-contact-mini-view
                        contact-name="${this.selectedContact.first_name} ${
                          this.selectedContact.last_name
                        }"
                        .contactEmail=${this.selectedContact.email}
                    ></legl-contact-mini-view>
                </div>
                <div class="details-card__created-date">
                    <strong>Created</strong>
                    <p>
                        ${formatDate(this.application.created_at, {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: false,
                        })}
                    </p>
                </div>
                <div class="details-card__workflow">
                    <strong>Workflow</strong>
                    <p>${this.application.flow_name}</p>
                </div>
                <div class="details-card__requested-by">
                    <strong>Requested by</strong>
                    <p>${this.application.created_by.name}</p>
                </div>
                <div class="details-card__reviewer">
                    <strong>Reviewer</strong>
                    <p class="reviewer-name">${this.reviewerName}</p>
                    <a
                        class="change-reviewer"
                        href="#"
                        @click=${this.onUpdateClicked}
                        >change</a
                    >
                </div>
                <div class="details-card__optional-note">
                    <strong>Optional note</strong>
                    <p>${this.note ? this.note : "-"}</p>
                </div>
            </legl-card>
            <div class="button-container">
                <legl-button
                    size="medium"
                    variant="transparent"
                    class="button--close"
                    data-cy-summary-screen-close
                    @click=${() => this.onClose()}
                    >close</legl-button
                >
            </div>`;
  }
}

customElements.define("legl-manual-cdd-summary", LeglSummaryScreen);
