import { LitElement, css, html, nothing } from "lit";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { toastService } from "../../../../../../legl-ui/toast";
import { patch, post } from "../../../../../../legl-ui/utils/fetch";
import { formatDate } from "../../../../../../legl-ui/utils/functions";
import { pluralize } from "../../../../../../legl-ui/utils/pluralize";

export class RequestSuccessMessage extends LitElement {
  static get styles() {
    return css`
            .requestSuccessMessage__successPanel__remindersWrapper {
                grid-area: remindersWrapper / remindersWrapper /
                    remindersWrapper / remindersWrapper;
                justify-self: center;
                width: 100%;
            }
            .requestSuccessMessage__successPanel__remindersWrapper.--border {
                border: 1px solid var(--legl-grey-borders);
                border-radius: 16px;
                padding: 55px 16px 55px 24px;
            }
            .requestSuccessMessage__successPanel__remindersWrapper
                .--border
                .requestSuccessMessage__createButton {
                grid-area: none;
                margin: 0;
                font-size: 14px;
                font-weight: 600;
                display: flex;
                justify-content: center;
            }
            .requestSuccessMessage__successPanel__remindersWrapper
                .requestSuccessMessage__copyButton {
                grid-area: none;
                justify-self: none;
                margin: 8px 0 0 0;
                font-size: 12px;
                font-weight: 600;
                display: flex;
                flex-direction: row;
                justify-content: end;
                width: auto;
            }
            .requestSuccessMessage__successPanel__remindersWrapper
                .requestSuccessMessage__successButton {
                width: auto;
                padding: 16px 24px;
            }
            .requestSuccessMessage__successPanel__remindersWrapper
                .requestSuccessMessage__copy-button
                legl-copy-button {
                color: var(--legl-grey-dark);
            }
            .requestSuccessMessage__reminderDescription {
                margin-top: 0;
                text-align: center;
            }

            .requestSuccessMessage__successPanel__remindersWrapper
                .reminderDelayDescription {
                font-weight: 400;
                font-size: 12px;
                line-height: 23px;
                text-align: left;
                padding: 13px 21px 20px 34px;
                margin-top: 21px;
            }

            .reminderDelayDescription {
                font-weight: 400;
                font-size: 12px;
                line-height: 23px;
                text-align: left;
                background-color: #f4f5f7;
                margin: 24px 0;
                border-radius: 4px;
            }

            .reminderDelayDescription.--textLeft {
                text-align: left;
            }

            .requestSuccessMessage__createButton {
                text-align: center;
                margin-top: 15px;
                display: flex;
                justify-content: flex-end;
            }

            .reminderHeading {
                display: flex;
                align-items: center;
                color: ${neutral["700"]};
            }

            .reminderHeading lds-icon {
                margin-right: 4px;
            }

            .reminderDelayDescription__text {
                margin: 0;
            }

            .emailOptions__div {
                display: flex;
                justify-content: left;
                gap: 10px;
            }

            .emailOptions__checkbox lds-tooltip {
                display: flex;
                align-items: center;
            }

            .emailOptions__checkbox {
                display: flex;
                align-items: center;
                font-size: 14px;
                justify-content: right;
                color: ${neutral["800"]};
                gap: 5px;
            }

            .requestSuccessMessage__content {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
            .email-message {
                font-weight: 700;
            }
        `;
  }

  static get properties() {
    return {
      product: {
        type: String,
      },
      selectedContact: {
        type: Object,
      },
      requestInfo: {
        type: Object,
      },
      paymentPlan: {
        attribute: false,
      },
      hasInitialEmailBeenSentOrScheduled: {
        type: Boolean,
        state: true,
      },
      requestLink: {
        type: String,
      },
      reminderStartAfterDays: {
        type: Number,
      },
      bccEnabled: {
        type: String,
      },
      ccOnRequestEmail: {
        type: String,
      },
      showCcCreatorCheckbox: {
        type: Boolean,
        state: true,
      },
      ccCreatorCheckboxChecked: {
        type: Boolean,
        state: true,
      },
      reminderStartAfterDaysOptions: {
        type: Array,
      },
      emailRemindersEnabled: {
        type: String,
        state: true,
      },
    };
  }

  constructor() {
    super();
    this.product = null;
    this.requestInfo = null;
    this.emailRemindersEnabled = "On";
    this.ccCreator = false;
    this.requestLink = null;
    this.hasInitialEmailBeenSentOrScheduled = false;
  }

  get sendEmailUrl() {
    return this.product === "pay"
      ? `/api/payments/send_payments_email/${this.requestInfo?.reference}/`
      : `/api/engage_applications/${this.requestInfo?.id}/send_engage_email/`;
  }

  get paymentPlanActions() {
    return (
      this.paymentPlan && new Date(this.paymentPlan.start_date) > new Date()
    );
  }

  get patchRequestUrl() {
    return this.product === "pay"
      ? `/api/payments/${this.requestInfo?.id}/`
      : `/api/engage_applications/${this.requestInfo?.id}/`;
  }

  get remindersText() {
    return this.product === "pay"
      ? "or until the payment is made"
      : "or until the request has been completed";
  }

  get bccIsEnabled() {
    return this.bccEnabled != "Never";
  }

  actionCallback = async () =>
    Promise.all([
      await patch(this.patchRequestUrl, {
        body: JSON.stringify({
          reminders: {
            enabled: this.emailRemindersEnabled,
            reminder_start_after_days: this.reminderStartAfterDays,
          },
        }),
      }),
      await post(this.sendEmailUrl, {
        body: JSON.stringify({
          cc_creator: this.ccCreator,
        }),
      }),
      await new Promise((resolve, reject) => {
        this.hasInitialEmailBeenSentOrScheduled = true;
        if (this.product === "pay") {
          window.dispatchEvent(new CustomEvent("reload-payments-table"));
        } else {
          window.dispatchEvent(new CustomEvent("reload-engage-table"));
        }
        resolve();
      }),
    ]);

  scheduleEmail = async () =>
    Promise.all([
      await post(
        `/api/payment_schedules/${this.paymentPlan.uid}/schedule_payment_plan_email/`,
        {
          body: JSON.stringify({
            email_cc_creator: this.ccCreator,
          }),
        },
      ),
      await patch(this.patchRequestUrl, {
        body: JSON.stringify({
          reminders: {
            reminders_enabled_at: this.paymentPlan.start_date,
            enabled: this.emailRemindersEnabled,
            reminder_start_after_days: this.reminderStartAfterDays,
          },
        }),
      }),
      await new Promise((resolve, reject) => {
        this.hasInitialEmailBeenSentOrScheduled = true;
        resolve();
      }),
    ]);

  getReminderMessage() {
    return html`<p
            class="requestSuccessMessage__reminderDescription--textLeft--marginTop24"
        >
            Send your client the request link directly via Legl's automated,
            firm-branded email.
        </p>`;
  }

  getCopyMessage() {
    if (this.ccOnRequestEmail === "Always") {
      return html`<p class="requestSuccessMessage__reminderDescription--textLeft--marginTop24">You will receive a CC copy.</p>`;
    } else if (this.bccIsEnabled) {
      return html`<p class="requestSuccessMessage__reminderDescription--textLeft--marginTop24">You will receive a BCC copy.</p>`;
    } else {
      return nothing;
    }
  }

  getCcCheckboxState() {
    switch (this.ccOnRequestEmail) {
      case "Always":
        this.showCcCreatorCheckbox = false;
        this.ccCreatorCheckboxChecked = true;
        this.ccCreator = true;
        break;
      case "Never":
        this.showCcCreatorCheckbox = false;
        this.ccCreatorCheckboxChecked = false;
        this.ccCreator = false;
        break;
      case "Default On":
        this.showCcCreatorCheckbox = true;
        this.ccCreatorCheckboxChecked = true;
        this.ccCreator = true;
        break;
      default:
        // maps to "Default Off"
        this.showCcCreatorCheckbox = true;
        this.ccCreatorCheckboxChecked = false;
        this.ccCreator = false;
    }
  }

  get reminderScheduleMessage() {
    if (this.emailRemindersEnabled === "Off") {
      return html`Switch on to send automated reminders with this request.`;
    }

    if (this.reminderStartAfterDays > 1) {
      return html`The first reminder will be sent after
            ${this.reminderStartAfterDays} days. Subsequent reminders will be
            sent 3, 7, 14 and 30 days thereafter.`;
    }
    return html`Reminders will be sent after 1, 3, 7, 14 and 30 days or
        until the request has been completed.`;
  }

  newComponent() {
    return html`<div
            class="requestSuccessMessage__successPanel__remindersWrapper"
        >
            <legl-card>
                <h1 slot="title" variant="corner">Send Request</h1>
                <div class="requestSuccessMessage__content">
                    <div style="padding: 0px 10px 0px 10px;">
                        ${this.getReminderMessage()}
                        ${
                          this.paymentPlanActions
                            ? html`<p
                                    data-cy-schedule-email-message
                                    class="requestSuccessMessage__reminderDescription--textLeft--marginTop24 email-message"
                                  >This email can be sent today, or scheduled for ${formatDate(
                                    this.paymentPlan?.start_date,
                                    {
                                      month: "short",
                                      day: "numeric",
                                      year: "numeric",
                                    },
                                  )}.
                                  </p>`
                            : nothing
                        }
                        ${this.getCopyMessage()}
                        ${
                          this.showCcCreatorCheckbox
                            ? html`<div class="emailOptions__checkbox">
                                  <lds-checkbox
                                      label="Include me as CC"
                                      .disabled=${this.hasInitialEmailBeenSentOrScheduled}
                                      .checked=${this.ccCreatorCheckboxChecked}
                                      @model-value-changed=${(e) => {
                                        this.ccCreator =
                                          e.currentTarget.modelValue.checked;
                                      }}
                                      data-cy="cc-creator"
                                  ></lds-checkbox>
                                  <lds-tooltip
                                      text="Include to send your email address as CC in the automated email and visible to the receiver."
                                      placement="top-end"
                                  >
                                      <lds-icon
                                          class="info-outlined-tooltip-icon"
                                          small
                                          name="lds-icon-InfoOutlined"
                                      ></lds-icon>
                                  </lds-tooltip>
                              </div>`
                            : nothing
                        }
                    </div>
                    <div class="requestSuccessMessage__cta">
                        <div class="reminderDelayDescription">
                            <div class="reminderHeading">
                                <lds-icon
                                    small
                                    name="lds-icon-Clock"
                                    name="lds-icon-Clock"
                                >
                                </lds-icon>
                                <h2>Automated reminder settings</h2>
                            </div>
                            <div class="emailOptions__div">
                                <lds-select
                                    small
                                    label="Reminders"
                                    .disabled=${this.hasInitialEmailBeenSentOrScheduled}
                                    @model-value-changed=${(e) => {
                                      this.emailRemindersEnabled =
                                        e.currentTarget.modelValue;
                                    }}
                                    data-cy-success-request-include-reminders-select
                                >
                                    <select slot="input">
                                        <option value="On" selected>On</option>
                                        <option value="Off">Off</option>
                                    </select>
                                </lds-select>
                                <lds-select
                                    small
                                    label="Start reminders after"
                                    .disabled=${
                                      this.emailRemindersEnabled === "Off" ||
                                      this.hasInitialEmailBeenSentOrScheduled
                                    }
                                    @model-value-changed=${(e) => {
                                      this.reminderStartAfterDays =
                                        e.currentTarget.modelValue;
                                    }}
                                    data-cy-success-request-reminders-delay-select
                                >
                                    <select slot="input">
                                        ${this.reminderStartAfterDaysOptions.map(
                                          (delay) =>
                                            html`<option
                                                    ?selected=${
                                                      delay ===
                                                      this
                                                        .reminderStartAfterDays
                                                    }
                                                    value=${delay}
                                                >
                                                    ${pluralize(delay, "day")}
                                                </option>`,
                                        )}
                                    </select>
                                </lds-select>
                            </div>
                            <p class="reminderDelayDescription__text">
                                ${this.reminderScheduleMessage}
                            </p>
                        </div>
                        <div class="requestSuccessMessage__createButton">
                            <legl-tooltip-dynamic
                                .hover=${true}
                                .isEnabled=${!this.selectedContact?.email}
                            >
                                <span slot="content">
                                    <p class="tooltip-text">
                                        The contact does not have an email
                                        address. An email address can be
                                        added by viewing the contact page.
                                    </p>
                                </span>
                                <legl-async-action-button
                                    class="requestSuccessMessage__successButton"
                                    icon="lds-icon-Send"
                                    .variant=${this.paymentPlanActions ? "outlined" : "contained"}
                                    .colour=${this.paymentPlanActions ? "neutral" : "primary"}
                                    success-colour="success"
                                    size="medium"
                                    .disabled=${
                                      !this.selectedContact?.email ||
                                      this.hasInitialEmailBeenSentOrScheduled
                                    }
                                    .actionCallback=${this.actionCallback}
                                    .resetAfterMS=${30000}
                                    @action-success=${(e) =>
                                      toastService.showSuccess("Email sent!")}
                                    data-cy-send-email-button
                                >
                                    ${this.paymentPlanActions ? "Send email now" : "Send email"}
                                </legl-async-action-button>
                                ${
                                  this.paymentPlanActions
                                    ? html`<legl-async-action-button
                                    class="requestSuccessMessage__successButton"
                                    icon="lds-icon-Clock"
                                    colour="primary"
                                    success-colour="success"
                                    success-message="Schedule email"
                                    size="medium"
                                    .disabled=${
                                      !this.selectedContact?.email ||
                                      this.hasInitialEmailBeenSentOrScheduled
                                    }
                                    .actionCallback=${this.scheduleEmail}
                                    .resetAfterMS=${30000}
                                    @action-success=${(e) =>
                                      toastService.showSuccess(
                                        "Email scheduled!",
                                      )}
                                    data-cy-schedule-email-button
                                >
                                    Schedule email
                                </legl-async-action-button>`
                                    : nothing
                                }
                            </legl-tooltip-dynamic>
                        </div>
                    </div>
                </div>
            </legl-card>
            <div class="requestSuccessMessage__copyButton">
                <legl-copy-button
                    icon="legl-icon-copy-o"
                    variant="transparent"
                    size="small"
                    class="requestSuccessMessage__clipboardButton"
                    copy-value="${this.requestLink}"
                >
                    Copy shareable link
                </legl-copy-button>
            </div>
        </div>`;
  }

  render() {
    this.getCcCheckboxState();
    return this.newComponent();
  }
}

customElements.define("request-success-message", RequestSuccessMessage);
