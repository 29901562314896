import { LitElement, html, nothing } from "lit";
import { styleMap } from "lit/directives/style-map.js";
import { LdsToast } from "../../../../../../legl-ui/lds-toast";
import "../../../../../../legl-ui/number";
import { RenderPayment } from "../../../../../../legl-ui/payment-wrapper/services/render-payment";
import { put } from "../../../../../../legl-ui/utils/fetch";
import companyPaymentSettings from "../../config/company-payment-settings.js";
import "./phone-payment-details-form.js";
import "./phone-payment-edit-details.js";
import "./phone-payment-script.js";
import "./phone-payment-stripe-form.js";
import "./phone-payment-success.js";
import * as Sentry from "@sentry/browser";

export class LeglPhonePayment extends LitElement {
  get sectionHeaderContainerStyles() {
    return {
      padding: "24px",
      "background-color": "#FFFFFF",
      "border-radius": "4px",
      "margin-bottom": "20px",
    };
  }

  get sectionHeaderStyles() {
    return {
      "font-family": `'Lato', sans-serif`,
      "font-size": "24px",
      "line-height": "32px",
      "font-weight": "bold",
      color: "#091C42",
      margin: "0",
    };
  }

  get nextButtonStyles() {
    return {
      display: "flex",
      "justify-content": "flex-end",
      "margin-top": "32px",
    };
  }

  static get properties() {
    return {
      phonePaymentDetails: {
        state: true,
      },
      phonePaymentDetailsSubmitted: {
        state: true,
      },
      isDetailsFormValid: {
        state: true,
      },
      minimumPayment: {
        state: true,
      },
      maximumPhonePayment: {
        state: true,
      },
      acceptHigherFeeCards: {
        state: true,
      },
      referenceFieldConfigs: {
        state: true,
      },
      selectedContact: {
        state: true,
      },
      paymentComplete: {
        state: true,
      },
      isLoading: {
        state: true,
      },
    };
  }

  constructor() {
    super();
    this.isLoading = false;
    this.isDetailsFormValid = false;
    this.selectedContact = null;
    this.paymentComplete = false;
    this.phonePaymentDetailsSubmitted = false;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("phone-payment-complete", () => {
      this.updatePaymentComplete();
      this.requestUpdate();
    });
  }

  async firstUpdated() {
    super.firstUpdated();
    await companyPaymentSettings.fetch();
    this.minimumPayment = companyPaymentSettings.minimumPayment;
    this.maximumPhonePayment = companyPaymentSettings.maximumPhonePayment;
    this.acceptHigherFeeCards = companyPaymentSettings.acceptHigherFeeCards;
    this.referenceFieldConfigs = companyPaymentSettings.referenceFieldConfigs;
  }

  updatePaymentComplete() {
    this.paymentComplete = true;
  }

  get currentStep() {
    if (this.phonePaymentDetailsSubmitted) {
      return "card-details";
    }
    if (this.selectedContact) {
      return "payment-information";
    }
    return "contact-selection";
  }

  get detailsForm() {
    return this.querySelector("legl-phone-payment-details-form");
  }

  get loadAdyenPayComponent() {
    return waffle.flag_is_active("adyen_moto_payments");
  }

  createRenderRoot() {
    return this;
  }

  formatPaymentUrl(url, reference) {
    return url.replace(/\/payments\/\d+\//, `/payment-session/${reference}/`);
  }

  get isCurrentStepValid() {
    if (this.currentStep === "payment-information" && this.isDetailsFormValid) {
      return true;
    }

    return false;
  }

  async submitPaymentDetails() {
    this.detailsForm.validate();
    if (!this.isCurrentStepValid) {
      document
        .querySelector("legl-drawer")
        .shadowRoot.querySelector(".drawer__content").scrollTop = 270;
      return;
    }
    try {
      this.isLoading = true;
      this.phonePaymentDetails = await this.detailsForm.submit();
      /* Need to propagate stripe elements all the way up through slots as it doesn't work in shadow dom */
      if (this.loadAdyenPayComponent) {
        // for adyen need to make a call to create adyen session and then return session details
        const paymentUrl = this.formatPaymentUrl(
          this.phonePaymentDetails.url,
          this.phonePaymentDetails.reference,
        );
        const sessionUrl = paymentUrl + "session/";
        const res = await put(sessionUrl, {
          body: JSON.stringify({
            provider: "adyen",
            method: "card",
          }),
        });
        if (!res.ok) {
          Sentry.captureMessage(`Adyen session error.·${res}`);
          throw new Error("Unable to create adyen session");
        }
        let session = {};
        const data = await res.json();
        session = data.provider_details;
        session.paymentUrl = paymentUrl;
        this.phonePaymentDetailsSubmitted = true;
        await this.updateComplete;
        await RenderPayment.render(
          "adyen",
          this.phonePaymentDetails.amount,
          session,
        );
      } else {
        this.phonePaymentDetailsSubmitted = true;
        await this.updateComplete;
      }
    } catch (e) {
      LdsToast.showError({
        text: "There was an error submitting your payment details. Please try again later.",
      });
      return;
    } finally {
      this.isLoading = false;
    }
    this.requestUpdate();
  }

  onContactChanged(e) {
    if (!e?.detail?.uid) {
      this.selectedContact = null;
      this.phonePaymentDetails = null;
      this.phonePaymentDetailsSubmitted = false;
    }
    this.selectedContact = e.detail;
  }

  render() {
    return html`
          <div data-cy-pp-create-payment-request-drawer>
            ${
              this.paymentComplete
                ? html`<phone-payment-success
                  .phonePaymentDetails=${this.phonePaymentDetails}
                ></phone-payment-success>`
                : html`<legl-contact-selector
            .selectedContactUid=${this.params.get("contactUid")}
            .hideExpandButton=${true}
            @contact-changed=${this.onContactChanged}
            .emailRequired="${false}"
          ></legl-contact-selector>
          <div style=${styleMap(this.sectionHeaderContainerStyles)}>
            <div>
              <h2 style=${styleMap(this.sectionHeaderStyles)}>Payment information</h2>
            </div>
            ${
              this.currentStep === "payment-information"
                ? html`
              <legl-phone-payment-details-form
                .phonePaymentDetails=${this.phonePaymentDetails}
                .selectedContact=${this.selectedContact}
                .referenceFieldConfigs=${this.referenceFieldConfigs}
                .minimumPayment=${this.minimumPayment}
                .maximumPhonePayment=${this.maximumPhonePayment}
                @form-values-changed=${(e) => {
                  this.isDetailsFormValid = e.detail.isValid;
                }}
              >
              </legl-phone-payment-details-form>
                <div style=${styleMap(this.nextButtonStyles)}>
                <lds-button
                  data-cy-pp-create-phone-payment-continue-button
                  data-cy-phone-payment-continue
                  .disabled=${this.isLoading}
                  .isLoading=${this.isLoading}
                  colour="primary"
                  @click=${this.submitPaymentDetails}
                  >Next</lds-button
                >
              </div>
            `
                : nothing
            }
            ${
              this.phonePaymentDetailsSubmitted && this.phonePaymentDetails
                ? html`
                <legl-phone-payment-edit-details .phonePaymentDetails=${
                  this.phonePaymentDetails
                } @edit-payment-details=${() => {
                  this.phonePaymentDetailsSubmitted = false;
                }}></legl-phone-payment-edit-details>
                `
                : nothing
            }
          </div>
          <div style=${styleMap(this.sectionHeaderContainerStyles)}>
            <div>
              <h2 style=${styleMap(this.sectionHeaderStyles)}>Card details</h2>
            </div>
            ${
              this.currentStep === "card-details"
                ? html`
            ${
              this.loadAdyenPayComponent
                ? html`
                  <phone-payment-script
                    .amount=${this.phonePaymentDetails?.amount}
                  ></phone-payment-script>
                  <div id="payment-method-component"></div>
                `
                : html`
                  <legl-phone-payment-stripe-form
                    .contactUid=${this?.selectedContact?.uid}
                    .phonePaymentDetails=${this.phonePaymentDetails}
                    .acceptHigherFeeCards=${this.acceptHigherFeeCards}
                  ></legl-phone-payment-stripe-form>
                `
            }
            `
                : nothing
            }
            </div>
                `
            }
          </div>
    `;
  }
}

customElements.define("legl-phone-payment", LeglPhonePayment);
