export const WaffleFlags = {
  SIGNATURE_REQUEST_SWITCH: "signature_request",
  ENABLE_LEGL_ASSIST_CDD: "enable_legl_assist_cdd",
  SHOW_CLIENT_EMAIL_FOOTER_SWITCH: "show_client_email_footer_switch",
  ADYEN_MOTO_PAYMENTS: "adyen_moto_payments",
  CONFLICT_CHECKS_FLAG: "conflict_checks",
  ADYEN_APPLE_PAY_FLAG: "adyen-apple-pay",
  ADYEN_PAYSTEP_FLAG: "adyen-paystep",
  VUE_PAY_CHECKOUT_FLAG: "vue-pay-checkout",
  ADYEN_PAYMENT_PLANS: "adyen-payment-plans",
  STANDARD_CDD_NO_ID: "standard_cdd_no_id",
  ENTITIES_NAV_FLAG: "entities_nav_flag",
  USE_NEW_PAYMENT_METHOD_SERVICE: "use-new-payment-method-service",
  ADYEN_ALLOWED: "adyen-allowed",
};
