import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import * as Sentry from "@sentry/browser";
import { toastService } from "../../../legl-ui/toast";
import { put } from "../../../legl-ui/utils/fetch";
import "../../lds-alert";
import "./styles.css";
import axios from "axios";

import { LitElement, html, nothing } from "lit";
import { WaffleFlags } from "../../../waffle-flags";
import { countries } from "./countries";

const errorMessage = "Please try again with another card.";

export class LeglAdyenComponent extends LitElement {
  #MOUNT_ELEMENT_ID = "#pay-adyen-component";

  static get properties() {
    return {
      session: {},
      source: { type: String },
      clientKey: {},
      environment: {},
      onPaymentCompleted: {},
      sessionProcessUrl: {},
      isPaymentSubmitting: { type: Boolean },
      warningMessageTitle: { type: String },
      isCardValid: { type: Boolean },
      cardComponent: { state: true },
      isDesktop: { state: true },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("resize", () => {
      this.isDesktop = window.innerWidth > 768;
    });
  }

  createRenderRoot() {
    return this;
  }

  constructor() {
    super();
    this.warningMessageTitle = "";
    this.isPaymentSubmitting = false;
    this.isCardValid = true;
    this.isDesktop = window.innerWidth > 768;
  }

  async submitAdyenPayment() {
    if (this.isPaymentSubmitting) {
      return;
    }
    this.isPaymentSubmitting = true;
    this.cardComponent.submit();
    if (!this.cardComponent.isValid) {
      this.isPaymentSubmitting = false;
    }
  }

  async initializeAdyen() {
    const cardInputStyleObject = {
      base: {
        color: "#253658",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        lineHeight: "14px",
        fontSmoothing: "antialiased",
      },
      placeholder: {
        color: "#C5CAD3",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
      },
    };
    const configuration = {
      session: this.session,
      clientKey: this.clientKey,
      environment: this.environment,
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: true,
          styles: cardInputStyleObject,
          data: {
            holderName: " ",
          },
        },
      },
      onPaymentCompleted: (result, component) =>
        this.onPaymentCompleted(result, component),
    };

    // Create an instance of AdyenCheckout using the configuration object.
    this.checkout = await AdyenCheckout(configuration);

    // Create an instance of the Component and mount it to the container you created.
    this.#mountCardComponent(
      this.checkout.create("card", {
        onBinLookup: (binLookup) => this.checkCardIssuer(binLookup),
      }),
    );

    if (this.applePay) {
      await this.initializeApplePay();
    }
  }

  async getCompanyPaymentSettings() {
    await axios
      .get("/api/company_payment_settings/")
      .then((response) => {
        this.companyPaymentSettings = response.data;
      })
      .catch((error) => {
        Sentry.captureException(
          error,
          "Failed to fetch company payment settings in Adyen component.",
        );
      });
  }

  get validCountries() {
    this.warningMessageTitle = "We are unable to take a payment at this moment";
    if (!this.companyPaymentSettings) {
      return ["GB"];
    }
    if (
      this.companyPaymentSettings.accept_european_cards &&
      this.companyPaymentSettings.accept_international_non_european_cards
    ) {
      this.warningMessageTitle =
        "We are unable to accept card payments from this country";
      return [...countries.european, ...countries.international, "GB"];
    } else if (
      this.companyPaymentSettings.accept_european_cards &&
      !this.companyPaymentSettings.accept_international_non_european_cards
    ) {
      this.warningMessageTitle =
        "We are only able to accept UK or EU card payments";
      return [...countries.european, "GB"];
    } else if (
      !this.companyPaymentSettings.accept_european_cards &&
      this.companyPaymentSettings.accept_international_non_european_cards
    ) {
      this.warningMessageTitle = "We are unable to accept EU card payments";
      return [...countries.international, "GB"];
    }
    this.warningMessageTitle = "We are only able to accept UK card payments";
    return ["GB"];
  }

  checkCardIssuer(binLookup) {
    if (this.validCountries.includes(binLookup.issuingCountryCode)) {
      this.isCardValid = true;
    } else {
      this.isCardValid = false;
    }
    this.submitTelemetry(binLookup);
  }

  submitTelemetry(binLookup) {
    axios.post("/api/observability/", {
      type: "payment_card_entered",
      session_id: this.session.id,
      accept_european_cards: false,
      accept_international_non_european_cards: false,
      card_brand: binLookup.detectedBrands[0],
      card_issuing_country_code: binLookup.issuingCountryCode,
      card_allowed: this.isCardValid,
    });
  }

  get applePay() {
    return (
      waffle.flag_is_active("adyen-apple-pay") &&
      this.source !== "PHONE_PAYMENT"
    );
  }

  get applePayConfiguration() {
    return {
      supportedCountries: this.validCountries,
    };
  }

  async initializeApplePay() {
    const applePayComponent = this.checkout.create(
      "applepay",
      this.applePayConfiguration,
    );
    applePayComponent
      .isAvailable()
      .then(() => {
        this.hasMultiplePaymentMethods = true;
        applePayComponent.mount("#applepay-container");
        document.getElementById("or-pay-with").innerHTML = "Or pay with";
      })
      .catch((e) => {
        //Apple Pay is not available
      });
  }

  #mountCardComponent(component) {
    this.cardComponent = component.mount(this.#MOUNT_ELEMENT_ID);
  }

  dispatchPhonePaymentComplete() {
    this.dispatchEvent(
      new CustomEvent("phone-payment-complete", {
        bubbles: true,
        composed: true,
      }),
    );
  }

  dispatchEngagePaymentComplete() {
    this.dispatchEvent(
      new CustomEvent("engage-payment-complete", {
        bubbles: true,
        composed: true,
      }),
    );
  }

  async onPaymentCompleted(result, component) {
    if (result.resultCode === "Authorised") {
      try {
        const res = await put(this.sessionProcessUrl, {});
      } catch (exception) {
        console.log(exception);
      } finally {
        if (this.source === "PHONE_PAYMENT") {
          this.dispatchPhonePaymentComplete();
        } else if (this.source === "ENGAGE_PAYMENT") {
          this.dispatchEngagePaymentComplete();
        } else {
          if (window.isNewPayApp) {
            window.dispatchEvent(new CustomEvent("payment-completed"));
          } else {
            window.location.href = `/pay/success/`;
          }
        }
      }
    } else {
      toastService.showError(result.resultCode);
      this.#mountCardComponent(component);
      this.isPaymentSubmitting = false;
      if (result.resultCode === "Error") {
        Sentry.captureMessage(`Adyen payment failed.·${result.resultCode}`);
      }
    }
  }

  render() {
    return html`
      ${
        this.applePay
          ? html`
            <div data-cy-adyen-apple-pay id="applepay-container"></div>
            <div data-cy-or-pay-with id="or-pay-with"></div>`
          : nothing
      }
      ${
        this.isCardValid
          ? nothing
          : html`
            <lds-alert
              class="adyen-alert"
              data-testid="issuing-country-error-message"
              type="info"
              title="${this.warningMessageTitle}"
              message="${this.companyPaymentSettings ? "Please try again with another card." : "Please refresh the page and try again"}"
            />`
      }
      <div id="pay-adyen-component" data-testid="pay-adyen-component"></div>
      <div class="container-payment-submit">
        ${
          this.paymentAmount && this.cardComponent
            ? html`
              <lds-button
                style="${this.isDesktop ? "" : "width: 100%"}"
                data-cy-payment-submit-button
                role="button"
                id="payment-submit"
                colour="primary"
                variant="contained"
                icon="lds-icon-LockOutlined"
                ?is-loading="${this.isPaymentSubmitting}"
                ?disabled="${this.isPaymentSubmitting || !this.isCardValid}"
                @click="${this.submitAdyenPayment}"
              >Pay £${this.paymentAmount}
              </lds-button>`
            : nothing
        }
      </div>
    `;
  }
}

customElements.define("legl-adyen-component", LeglAdyenComponent);
